interface UseDataReturn {
  sizeHumanized: (size: number) => string;
}

export function useData(): UseDataReturn {
  const sizeHumanized = (size: number): string => {
    if (size === 0) return "0 B";
    const k = 1000;
    const dm = 2;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  return { sizeHumanized };
}
