<template>
  <div class="error">
    <slot>{{ $t("global.error_generic") }}</slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "b-alert",
});
</script>

<style scoped lang="scss">
.error {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 5px;
  color: #fff;
  border-radius: 4px;
  background: var(--error);
  line-height: 1.4;

  ::v-deep(a) {
    text-decoration: underline;
    padding-left: 5px;
  }
}
</style>
