<template>
  <input
    :type="type"
    :value="modelValue"
    :autofocus="autofocus"
    class="form-control"
    @input="$emit('update:modelValue', $event.target.value)"
    @change="$emit('change', $event.target.value)"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "b-input",
  props: {
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: [String, Number],
      required: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
.form-control {
  font-weight: 500;
  height: 40px;
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
  box-shadow: none;
  -webkit-appearance: none;
  transition: all ease-in-out 0.25s;

  &:focus {
    outline: none;
    border-color: var(--text-color);
    box-shadow: 0 0 5px var(--secondary);
    transition: all ease-in-out 0.25s;
  }

  &:disabled {
    background: #eee;
    color: #777;
    border-color: #777;
  }
}
</style>
