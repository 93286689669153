import http from "@/http";
import { AxiosPromise } from "axios";

export interface UploadingFile {
  file: File;
  progress: number;
}

export interface UploadedFile extends File {
  id: string;
}

export default {
  postFile(fileData: FormData, uploadingFile: UploadingFile): AxiosPromise {
    return http.post("/api/file", fileData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        uploadingFile.progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      },
    });
  },
  deleteFile(uploadedFile: UploadedFile): AxiosPromise {
    return http.delete(`/api/file/${uploadedFile.id}`);
  },
};
