<template>
  <component
    :is="findTag"
    :to="to"
    :href="href"
    :type="type"
    class="btn"
    v-bind="$attrs"
  >
    <slot />
    <span v-if="loading" class="loading-icon"></span>
  </component>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "b-btn",
  props: {
    to: {
      required: false,
      type: Object,
    },
    href: {
      required: false,
      type: String,
      default: null,
    },
    type: {
      required: false,
      type: String,
      default: "button",
    },
    loading: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    findTag() {
      if (this.to) {
        return "router-link";
      } else if (this.href !== null) {
        return "a";
      } else {
        return "button";
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.btn {
  background: var(--primary);
  color: white;
  padding: 0 16px;
  height: 36px;
  min-width: 64px;
  align-items: center;
  flex: 0 0 auto;
  font-size: var(--font-sm);
  justify-content: center;
  outline: 0;
  display: inline-flex;
  border-radius: 4px;
  position: relative;
  letter-spacing: 0.08rem;
  cursor: pointer;

  &::before {
    background-color: currentColor;
    border-radius: inherit;
    bottom: 0;
    color: inherit;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }

  &:hover::before {
    opacity: 0.08;
  }
}

.step-icon {
  display: none;
  margin-left: 10px;
  fill: #fff;
  width: 30px;
  height: 30px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-icon {
  display: block;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  animation: spin 2s linear infinite;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.5); /* Light grey */
  border-top: 3px solid #fff;
  box-sizing: border-box;
}
</style>
