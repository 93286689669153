import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { getUserSupportedLang } from "@/i18n";
import Root from "./Root.vue";

const load = (component: string) => {
  return () =>
    import(/* webpackChunkName: "[request]" */ `@/views/${component}.vue`);
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:locale",
    component: Root,
    beforeEnter(to, from, next) {
      const locale = String(to.params.locale);
      (document.querySelector("html") as HTMLElement).lang = locale;

      if (!["en", "fr"].includes(locale)) {
        return next({
          name: String(to.name),
          params: { locale: "en" },
        });
      }

      return next();
    },
    children: [
      {
        path: "",
        name: "Home",
        component: load("Home"),
      },
      {
        path: "form-download",
        name: "FormDownload",
        component: load("FormDownload"),
      },
      {
        path: "form-submission",
        name: "FormSubmission",
        component: load("FormSubmission"),
      },
      {
        path: "form-medical-questionnaire",
        name: "FormMedicalQuestionnaire",
        component: load("FormMedicalQuestionnaire"),
      },
      {
        path: "confirmed",
        name: "Confirmed",
        component: load("Confirmed"),
      },
      {
        path: "complement/:reference",
        component: load("complement/Root"),
        children: [
          {
            path: "choice-of-additional-deposit",
            name: "ChoiceAdditionalDeposit",
            component: load("complement/ChoiceAdditionalDeposit"),
          },
          {
            path: "bia",
            name: "ComplementBia",
            component: load("complement/BIA"),
          },
          {
            path: "medical-questionnaire",
            name: "ComplementQm",
            component: load("complement/MedicalQuestionnaire"),
          },
          {
            path: "confirmed",
            name: "ComplementConfirmed",
            component: load("complement/Confirmed"),
          },
        ],
      },
      {
        path: "404",
        name: "NotFound",
        component: load("404"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect() {
      return getUserSupportedLang();
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
