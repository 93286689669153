
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "b-select",
  props: {
    modelValue: {
      type: [String, Number, Object],
      required: false,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
  },
  setup() {
    const isFocus = ref<boolean>(false);

    return { isFocus };
  },
});
