import { Plugin } from "vue";
import Container from "@/components/base/Container.vue";
import Row from "@/components/base/Row.vue";
import Title from "@/components/base/Title.vue";
import Information from "@/components/base/Information.vue";
import Button from "@/components/base/Button.vue";
import Input from "@/components/base/Input.vue";
import FormRow from "@/components/base/FormRow.vue";
import FormError from "@/components/base/FormError.vue";
import FormUpload from "@/components/base/FormUpload.vue";
import Alert from "@/components/base/Alert.vue";
import Recaptcha from "@/components/base/Recaptcha.vue";
import Select from "@/components/base/Select.vue";
import Dialog from "@/components/base/Dialog.vue";

const components: Plugin = {
  install: (app): void => {
    app.component(FormError.name, FormError);
    app.component(FormRow.name, FormRow);
    app.component(FormUpload.name, FormUpload);
    app.component(Input.name, Input);
    app.component(Row.name, Row);
    app.component(Container.name, Container);
    app.component(Title.name, Title);
    app.component(Information.name, Information);
    app.component(Button.name, Button);
    app.component(Alert.name, Alert);
    app.component(Recaptcha.name, Recaptcha);
    app.component(Select.name, Select);
    app.component(Dialog.name, Dialog);
  },
};

export default components;
