<template>
  <div class="title">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "b-title",
});
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.6rem;
  color: var(--title);
  padding: 20px;
  line-height: 1;
}

::v-deep(.icon) {
  width: 30px;
  max-height: 30px;
  margin-right: 10px;
  flex-shrink: 0;
  fill: var(--title);
}
</style>
