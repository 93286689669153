<template>
  <img src="@/assets/banner.jpg" alt="ACS banner" />
</template>

<style lang="scss" scoped>
img {
  display: flex;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 190px;
}
</style>
