
import { defineComponent } from "vue";

export default defineComponent({
  name: "b-input",
  props: {
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: [String, Number],
      required: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
});
