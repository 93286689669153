
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "b-dialog",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const showDialog = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const onClick = () => {
      showDialog.value = !showDialog.value;
    };

    return { onClick, showDialog };
  },
});
