import { BiaFormInterface } from "@/forms/useBiaForm";
import { Step1FormInterface } from "@/forms/useStep1Form";
import { Step2FormInterface } from "@/forms/useStep2Form";
import http from "@/http";
import { AxiosPromise } from "axios";

export default {
  getReference(reference: string): AxiosPromise {
    return http.get(`/api/reference/${reference}`);
  },
  postReference(): AxiosPromise {
    return http.post("/api/reference");
  },
  getForm(): AxiosPromise {
    return http.get("/api/form");
  },
  getStep1(): AxiosPromise {
    return http.get("/api/step-1");
  },
  postStep1(payload: Step1FormInterface): AxiosPromise {
    return http.post("/api/step-1", payload);
  },
  getStep2(): AxiosPromise {
    return http.get("/api/step-2");
  },
  postStep2(payload: Step2FormInterface): AxiosPromise {
    return http.post("/api/step-2", payload);
  },
  postStep3(): AxiosPromise {
    return http.post("/api/step-3");
  },
  postBia(payload: BiaFormInterface): AxiosPromise {
    return http.post("/api/bia", payload);
  },
};
