<template>
  <div
    @drop.prevent="addFile"
    @dragover.prevent
    @dragenter="dragging = true"
    @dragend="dragging = false"
    @dragleave="dragging = false"
    :class="['upload', dragging ? 'dragged' : '']"
  >
    <span class="placeholder">
      <slot name="placeholder" />
    </span>
    <ul class="list">
      <li v-for="file in modelValue" :key="file.id">
        <span class="info">
          <span class="info-name">{{ file.name }}</span>
        </span>
        <em class="info-size">({{ sizeHumanized(file.size) }})</em>
        <button @click.prevent="removeFile(file)" class="delete">
          {{ $t("global.remove") }}
        </button>
      </li>
      <li v-for="uploadingFile in uploadingFilesList" :key="uploadingFile.file">
        <span class="info">
          <span class="info-name">{{ uploadingFile.file.name }}</span>
          <span
            class="info-loader"
            :style="{ width: `${uploadingFile.progress}%` }"
          ></span>
        </span>
        <em class="info-size">{{
          $t("global.progress_download", { percent: uploadingFile.progress })
        }}</em>
      </li>
    </ul>

    <label class="click-area">
      <input
        type="file"
        @change="addFile"
        multiple
        :accept="mimeTypes.toString()"
      />
    </label>
    <b-form-error
      class="errors"
      v-if="validation?.$error"
      :errors="validation?.$errors"
    ></b-form-error>
    <b-form-error
      class="errors"
      v-if="errors.length > 0"
      :errors="errors"
    ></b-form-error>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref } from "vue";
import fileHttp, { UploadingFile, UploadedFile } from "@/http/fileHttp";
import { useData } from "@/use/useData";

interface UploadingFilesList {
  [key: string]: UploadingFile;
}

export default defineComponent({
  name: "b-form-upload",
  props: {
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Array,
      required: false,
      default: (): string[] => [],
    },
    maxFileSize: {
      type: Number,
      default: 20 * 1024 * 1024,
    },
    maxFiles: {
      type: Number,
      default: 10,
    },
    mimeTypes: {
      type: Array,
      default: () => [
        "application/pdf",
        "image/jpeg",
        "image/webp",
        "image/png",
      ],
    },
    validation: {
      type: Object,
      required: false,
    },
  },
  setup(props, { emit }) {
    const isDragging = ref<boolean>(false);
    const errors = ref<
      { $message: string; $params: Record<string, unknown> }[]
    >([]);

    /** Manipulation des fichiers déjà téléchargés */
    const uploadedFiles = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    /** Manipulation des fichiers en cours de téléchargement */
    const uploadingFilesList: UploadingFilesList = reactive({});

    const validationFile = (file: File): boolean => {
      if (uploadedFiles.value.length >= props.maxFiles) {
        errors.value.push({
          $message: "limit_files",
          $params: { maxFiles: props.maxFiles },
        });
        return false;
      }

      if (file.size > props.maxFileSize) {
        errors.value.push({
          $message: "too_large",
          $params: { maxSize: props.maxFileSize },
        });
        return false;
      }

      if (!props.mimeTypes.includes(file.type)) {
        errors.value.push({
          $message: "file_format",
          $params: { file: file.name },
        });
        return false;
      }

      return true;
    };

    const addFile = async (event: Event) => {
      errors.value = [];
      const droppedFiles =
        (event.target as HTMLInputElement).files ||
        (event as DragEvent).dataTransfer?.files;

      if (!droppedFiles) return;

      [...droppedFiles].forEach((file: File) => {
        if (validationFile(file)) {
          uploadFile(file);
        }
      });
    };

    const uploadFile = async (file: File) => {
      const formData = new FormData();
      formData.append("type", props.name);
      formData.append("file", file);

      const index = `${new Date().valueOf()}_${Math.floor(
        Math.random() * 10000
      )}`;

      try {
        uploadingFilesList[`file_${index}`] = {
          file,
          progress: 0,
        };

        const { data } = await fileHttp.postFile(
          formData,
          uploadingFilesList[`file_${index}`]
        );

        /** Ajout du fichier dans la liste des fichiers uploadés */
        uploadedFiles.value.push(data.content);
      } catch ({ response }) {
        console.log(response);
      } finally {
        /** Retrait du fichier de la liste des fichiers en cours de téléchargement */
        delete uploadingFilesList[`file_${index}`];
      }
    };

    const removeFile = async (fileToRemove: UploadedFile) => {
      try {
        await fileHttp.deleteFile(fileToRemove);
        uploadedFiles.value = props.modelValue.filter((fileModel) => {
          return fileToRemove !== fileModel;
        });
      } catch ({ response }) {
        console.log(response);
      }
    };

    return {
      isDragging,
      addFile,
      uploadFile,
      removeFile,
      uploadingFilesList,
      sizeHumanized: useData().sizeHumanized,
      errors,
    };
  },
});
</script>

<style lang="scss" scoped>
.upload {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px dashed #207890;
  border-radius: 4px;
  max-width: 500px;
  min-height: 100px;
  padding: 20px;
  transition: all 0.25s;

  &:hover,
  &.dragged {
    background: rgba(0, 0, 0, 0.05);

    .placeholder::after {
      transform: rotate(90deg);
      transition: all 0.25s;
    }
  }
}

.placeholder {
  position: relative;
  color: var(--label);
  font-size: 16px;
  text-align: center;
  padding: 0 30px;

  &::after {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    margin: 10px auto;
    background: linear-gradient(
        to bottom,
        transparent 35%,
        var(--primary) 35%,
        var(--primary) 65%,
        transparent 65%
      ),
      linear-gradient(
        to right,
        transparent 35%,
        var(--primary) 35%,
        var(--primary) 65%,
        transparent 65%
      );
  }
}

.list {
  position: relative;
  z-index: 20;
  width: 100%;

  li {
    display: flex;
    align-items: center;
    position: relative;
    color: var(--secondary);
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px;
    margin: 10px 0;
    height: 40px;
    background: #fff;
    overflow: hidden;
  }

  .info {
    display: table;
    table-layout: fixed;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--font-sm);
  }

  .info-name {
    display: table-cell;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;
    width: 100%;
  }

  .info-loader {
    position: absolute;
    width: 0%;
    height: 4px;
    bottom: 0;
    left: 0;
    background: var(--secondary);
    transition: all ease-in 0.25s;
  }

  .delete {
    background: var(--error);
    color: var(--white);
    padding: 5px 10px;
    margin-left: 10px;
    border-radius: 4px;
    white-space: nowrap;
    font-size: var(--font-sm);
  }
}

.info-size {
  white-space: nowrap;
  font-size: var(--font-sm);
}

.click-area {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;

  input {
    display: none;
  }
}

.errors {
  border-radius: 4px;
  padding: 5px;
  font-size: 0.75rem;

  ::v-deep(li) {
    padding: 2px 0;
  }
}
</style>
