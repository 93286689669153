import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import localePath from "./plugins/localePath";
import components from "./plugins/components";

const app = createApp(App);

app.use(i18n);
app.use(store);
app.use(router);
app.use(localePath);
app.use(components);

app.mount("#app");

export default app;
