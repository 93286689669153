<template>
  <div
    class="form-group"
    :class="{
      'form-group--center': display === 'center',
      'has-error': validation?.$errors.length,
      'has-success': !validation?.$error && validation?.$dirty,
    }"
  >
    <label v-if="label" class="form-group__label">
      {{ $t("global.label_format", { label }) }}
    </label>
    <div
      class="form-group__input"
      :class="{ 'd-center': !label && display === 'center' }"
    >
      <slot></slot>
    </div>
    <b-form-error
      v-if="validation?.$error"
      :errors="validation?.$errors"
    ></b-form-error>
    <small class="help" v-if="help">{{ help }}</small>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "b-form-row",
  props: {
    display: {
      type: String,
      default: "column",
    },
    label: {
      type: [String, Promise],
      required: false,
      default: null,
    },
    help: {
      type: [String],
      required: false,
      default: null,
    },
    validation: {
      type: Object,
      required: false,
    },
  },
});
</script>

<style scoped lang="scss">
@import "../../scss/grid";

.form-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

label {
  display: flex;
  color: var(--label);
  font-size: 16px;
  width: 100%;
}

.has-error {
  ::v-deep(.form-control),
  .btn-upload,
  ::v-deep(textarea) {
    border-color: var(--error);
    border-radius: 4px 4px 0 0;

    &:focus {
      box-shadow: 0 0 5px var(--error);
    }
  }

  ul {
    border-radius: 0 0 4px 4px;
  }

  ::v-deep(.v-select) > div {
    border-radius: 4px 4px 0 0;
    border: 1px solid var(--error);
  }
}

.has-success {
  ::v-deep(.form-control),
  .btn-upload,
  ::v-deep(textarea) {
    border-color: var(--success);

    &:focus {
      box-shadow: 0 0 5px var(--success);
    }
  }
}

.form-group__input {
  width: 100%;
}

.form-group--center {
  justify-content: center;
  width: 100%;

  label {
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    width: 50%;
  }

  ul,
  .help {
    max-width: 300px;
    margin-left: 300px;
  }
}

.form-group--center .form-group__input {
  width: 50%;

  ::v-deep(.form-control),
  ::v-deep(.v-select),
  ::v-deep(.iti) {
    max-width: 300px;
  }
}

.help {
  display: flex;
  width: 100%;
  font-style: italic;
  font-size: var(--font-xs);
  margin-top: 5px;
}

@include media-breakpoint-down(sm) {
  .form-group {
    &__label {
      margin-bottom: 4px;
    }
  }

  .form-group--center {
    label {
      width: 100%;
      justify-content: start;
    }

    .form-group__input {
      width: 100%;

      ::v-deep(.form-control),
      ::v-deep(.v-select),
      ::v-deep(.iti) {
        max-width: 100%;
      }
    }

    ul,
    .help {
      max-width: 100%;
      margin-left: 0;
    }
  }
}
</style>
