import { Plugin } from "vue";
import { useI18n } from "vue-i18n";
import { LocationAsRelativeRaw } from "vue-router";

const localePath: Plugin = {
  install: (app): void => {
    app.config.globalProperties.$localePath = (
      routeInformation: LocationAsRelativeRaw
    ) => {
      const { locale } = useI18n();

      if (!routeInformation.params) {
        routeInformation.params = {};
      }

      routeInformation.params.locale = locale.value;

      return routeInformation;
    };
  },
};

export default localePath;
