<template>
  <header>
    <div class="container d-flex align-items-center">
      <h1>
        <router-link class="logo" :to="$localePath({ name: 'Home' })">
          <img src="@/assets/acs.svg" alt="ACS" />
        </router-link>
      </h1>
      <strong class="baseline">
        {{ $t("header.baseline") }}
      </strong>
    </div>
  </header>
</template>

<style lang="scss" scoped>
header {
  padding: 10px 0;
}

.logo {
  display: flex;
  width: 135px;
}

.baseline {
  color: var(--primary);
  font-weight: 700;
  padding-left: 30px;
  max-width: 450px;
}

@include media-breakpoint-down(sm) {
  .baseline {
    display: none;
  }
}
</style>
