
import { computed, defineComponent, reactive, ref } from "vue";
import fileHttp, { UploadingFile, UploadedFile } from "@/http/fileHttp";
import { useData } from "@/use/useData";

interface UploadingFilesList {
  [key: string]: UploadingFile;
}

export default defineComponent({
  name: "b-form-upload",
  props: {
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Array,
      required: false,
      default: (): string[] => [],
    },
    maxFileSize: {
      type: Number,
      default: 20 * 1024 * 1024,
    },
    maxFiles: {
      type: Number,
      default: 10,
    },
    mimeTypes: {
      type: Array,
      default: () => [
        "application/pdf",
        "image/jpeg",
        "image/webp",
        "image/png",
      ],
    },
    validation: {
      type: Object,
      required: false,
    },
  },
  setup(props, { emit }) {
    const isDragging = ref<boolean>(false);
    const errors = ref<
      { $message: string; $params: Record<string, unknown> }[]
    >([]);

    /** Manipulation des fichiers déjà téléchargés */
    const uploadedFiles = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    /** Manipulation des fichiers en cours de téléchargement */
    const uploadingFilesList: UploadingFilesList = reactive({});

    const validationFile = (file: File): boolean => {
      if (uploadedFiles.value.length >= props.maxFiles) {
        errors.value.push({
          $message: "limit_files",
          $params: { maxFiles: props.maxFiles },
        });
        return false;
      }

      if (file.size > props.maxFileSize) {
        errors.value.push({
          $message: "too_large",
          $params: { maxSize: props.maxFileSize },
        });
        return false;
      }

      if (!props.mimeTypes.includes(file.type)) {
        errors.value.push({
          $message: "file_format",
          $params: { file: file.name },
        });
        return false;
      }

      return true;
    };

    const addFile = async (event: Event) => {
      errors.value = [];
      const droppedFiles =
        (event.target as HTMLInputElement).files ||
        (event as DragEvent).dataTransfer?.files;

      if (!droppedFiles) return;

      [...droppedFiles].forEach((file: File) => {
        if (validationFile(file)) {
          uploadFile(file);
        }
      });
    };

    const uploadFile = async (file: File) => {
      const formData = new FormData();
      formData.append("type", props.name);
      formData.append("file", file);

      const index = `${new Date().valueOf()}_${Math.floor(
        Math.random() * 10000
      )}`;

      try {
        uploadingFilesList[`file_${index}`] = {
          file,
          progress: 0,
        };

        const { data } = await fileHttp.postFile(
          formData,
          uploadingFilesList[`file_${index}`]
        );

        /** Ajout du fichier dans la liste des fichiers uploadés */
        uploadedFiles.value.push(data.content);
      } catch ({ response }) {
        console.log(response);
      } finally {
        /** Retrait du fichier de la liste des fichiers en cours de téléchargement */
        delete uploadingFilesList[`file_${index}`];
      }
    };

    const removeFile = async (fileToRemove: UploadedFile) => {
      try {
        await fileHttp.deleteFile(fileToRemove);
        uploadedFiles.value = props.modelValue.filter((fileModel) => {
          return fileToRemove !== fileModel;
        });
      } catch ({ response }) {
        console.log(response);
      }
    };

    return {
      isDragging,
      addFile,
      uploadFile,
      removeFile,
      uploadingFilesList,
      sizeHumanized: useData().sizeHumanized,
      errors,
    };
  },
});
