
import { defineComponent, onBeforeMount, ref } from "vue";

interface RecaptchaWindow extends Window {
  recaptchaReady: () => void;
}

declare let window: RecaptchaWindow;
declare let grecaptcha: any;

export default defineComponent({
  name: "b-recaptcha",
  props: {},
  setup(_options, { emit }) {
    const lang = (document.querySelector("html") as HTMLElement).lang;
    const recaptchaRef = ref(null);

    const recaptchaRender = () => {
      grecaptcha.render(recaptchaRef.value, {
        sitekey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY,
        theme: "light",
        size: "normal",
        callback: (response: Response) => emit("verify", response),
        "expired-callback": () => emit("expire"),
        "error-callback": () => emit("fail"),
      });
    };

    onBeforeMount(() => {
      const script = document.createElement("script");
      script.id = "__RECAPTCHA_SCRIPT";
      script.src = `https://www.google.com/recaptcha/api.js?onload=recaptchaReady&render=explicit&hl=${lang}`;
      script.async = true;
      script.defer = true;

      document.head.appendChild(script);

      window.recaptchaReady = () => {
        recaptchaRender();
      };
    });

    return { recaptchaRef };
  },
});
