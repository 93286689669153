<template>
  <core-top-bar />
  <core-header />
  <core-banner />
  <b-container class="m-t-20 m-b-20" v-if="showAlert">
    <b-alert>{{ $t(showAlert) }}</b-alert>
  </b-container>
  <router-view v-if="isReady" />
  <core-footer />
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from "vue";
import CoreTopBar from "./components/core/TopBar.vue";
import CoreHeader from "./components/core/Header.vue";
import CoreBanner from "./components/core/Banner.vue";
import CoreFooter from "./components/core/Footer.vue";
import { useSubscriber } from "@/use/useSubscriber";

export default defineComponent({
  components: { CoreTopBar, CoreHeader, CoreBanner, CoreFooter },
  setup() {
    const subscriber = useSubscriber();
    const isReady = ref<boolean>(false);
    const showAlert = ref<string>("");

    onBeforeMount(async () => {
      const hasReference = await subscriber.hasReference();
      if (hasReference) {
        isReady.value = true;
        return;
      }

      try {
        await subscriber.newReference();
        isReady.value = true;
      } catch ({ response }) {
        showAlert.value =
          response.status === 429
            ? "global.error_too_many"
            : "global.error_generic";
      }
    });

    return { isReady, showAlert };
  },
});
</script>

<style lang="scss">
// enable scss
</style>
