import axios, { AxiosInstance } from "axios";

const http: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_DOMAIN,
});

http.interceptors.request.use(
  (request) => {
    request.headers["Content-Type"] = "application/json";
    request.headers["X-LOCALE"] = (
      document.querySelector("html") as HTMLElement
    ).lang;

    const reference = localStorage.getItem("reference");
    if (reference && !request.headers["X-REFERENCE"]) {
      request.headers["X-REFERENCE"] = reference;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
