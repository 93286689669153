<template>
  <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const { locale } = useI18n();
    const router = useRouter();
    const route = useRoute();

    locale.value = String(route.params.locale);

    router.beforeEach((to, from, next) => {
      if (to.params.locale === from.params.locale) {
        next();
      }

      const { locale: localeParams } = to.params;
      locale.value = String(localeParams);
      (document.querySelector("html") as HTMLElement).lang = locale.value;

      next();
    });
  },
});
</script>
