
import { defineComponent } from "vue";

export default defineComponent({
  name: "b-btn",
  props: {
    to: {
      required: false,
      type: Object,
    },
    href: {
      required: false,
      type: String,
      default: null,
    },
    type: {
      required: false,
      type: String,
      default: "button",
    },
    loading: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    findTag() {
      if (this.to) {
        return "router-link";
      } else if (this.href !== null) {
        return "a";
      } else {
        return "button";
      }
    },
  },
});
