<template>
  <ul v-show="errors.length">
    <li v-for="error in errors" :key="error">
      {{ $t(`form.${error.$message}`, error.$params) }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "b-form-error",
  props: {
    errors: {
      type: Array,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  color: #fff;
  background: var(--error);
  font-size: 0.75rem;

  li {
    ::v-deep(a) {
      text-decoration: underline;
    }
  }
}
</style>
