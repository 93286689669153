<template>
  <div class="dialog">
    <slot name="activator" :on="{ onClick }" />
    <div
      v-if="showDialog"
      class="dialog-cache"
      @click.self="showDialog = false"
    >
      <div class="dialog-content">
        <button
          @click="showDialog = false"
          class="dialog-close"
          :title="$t('global.close')"
        >
          {{ $t("global.close") }}
        </button>
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "b-dialog",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const showDialog = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const onClick = () => {
      showDialog.value = !showDialog.value;
    };

    return { onClick, showDialog };
  },
});
</script>

<style lang="scss" scoped>
.dialog-cache {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background: rgba(55, 58, 71, 0.9);
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.dialog-content {
  position: relative;
  z-index: 10;
  width: 80%;
  max-width: 560px;
  background: #fff;
  padding: 35px 35px 35px;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
}

.dialog-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  background: var(--error);
  color: var(--error);
  border-radius: 0 0 0 4px;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 15px;
    height: 2px;
    background: #fff;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    opacity: 0.9;
  }
}
</style>
