import { createI18n, LocaleMessages, VueMessageType } from "vue-i18n";

export const SUPPORT_LOCALES = ["en", "fr"];

export const getUserSupportedLang = (): string => {
  let locale = navigator.language;

  if (locale === "fr-FR") {
    locale = "fr";
  }

  if (SUPPORT_LOCALES.includes(locale)) {
    return locale;
  }

  return "en";
};

const loadLocaleMessages = () => {
  const locales = require.context(
    "@/i18n/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const messages: LocaleMessages<VueMessageType> = {};

  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
};

const i18n = createI18n({
  locale: "fr",
  fallbackLocale: "en",
  messages: loadLocaleMessages(),
});

export default i18n;
