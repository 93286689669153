<template>
  <div class="select-wrapper" :class="{ 'is-active': isFocus }">
    <select
      class="form-control"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      @focus="isFocus = true"
      @blur="isFocus = false"
    >
      <option v-if="placeholder" disabled value="">{{ placeholder }}</option>
      <option v-for="option in options" :value="option.key" :key="option.key">
        {{ option.value }}
      </option>
    </select>
    <span class="arrow"></span>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "b-select",
  props: {
    modelValue: {
      type: [String, Number, Object],
      required: false,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
  },
  setup() {
    const isFocus = ref<boolean>(false);

    return { isFocus };
  },
});
</script>

<style scoped lang="scss">
.form-control {
  font-weight: 500;
  position: relative;
  border: 0;
  min-width: 220px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--secondary);
  padding: 0.375rem 30px 0.375rem 0.75rem;
  box-shadow: none;
  -webkit-appearance: none;
  transition: all ease-in-out 0.25s;

  &:focus {
    outline: none;
    border-color: var(--text-color);
    box-shadow: 0 0 5px var(--secondary);
    transition: all ease-in-out 0.25s;
  }
}

.select-wrapper {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    right: 10px;
    top: 15px;
    transform: rotate(-45deg);
  }

  .arrow {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 10px;
    top: 50%;
    margin-top: -10px;
    transition: all ease-in-out 0.25s;

    &::before {
      content: "";
      position: absolute;
      display: block;
      border: 2px solid red;
      width: 10px;
      height: 10px;
      top: 3px;
      left: 5px;
      border: 2px solid var(--secondary);
      border-bottom: 0;
      border-left: 0;
      transform: rotate(135deg);
    }
  }

  &.is-active .arrow {
    transform: rotate(-180deg);
  }
}
</style>
