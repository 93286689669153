<template>
  <div class="row">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "b-row",
});
</script>
