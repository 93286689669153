<template>
  <div class="top-bar">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center">
        <p>{{ $t("header.top_title") }}</p>
        <div class="d-flex justify-content-between align-items-center">
          <flag-icon class="flag" />
          <router-link
            class="link"
            v-if="$i18n.locale === 'fr'"
            :to="{ name: $route.name, params: { locale: 'en' } }"
          >
            English
          </router-link>
          <router-link
            class="link"
            v-else
            :to="{ name: $route.name, params: { locale: 'fr' } }"
          >
            Français
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FlagIcon from "@/components/icon/FlagIcon.vue";

export default defineComponent({
  components: { FlagIcon },
});
</script>

<style lang="scss" scoped>
.top-bar {
  background: var(--primary);
  color: var(--white);
  padding: 5px 0;
}

.link {
  margin-left: 5px;
}

.flag {
  margin-left: 10px;
  height: 16px;
}
</style>
