<template>
  <div class="information">
    <b-container>
      <slot />
    </b-container>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "b-information",
});
</script>

<style lang="scss" scoped>
.information {
  color: #207890;
  padding: 20px 0;
  font-size: 1rem;
  margin-top: -0.25em;
  margin-bottom: -0.25em;

  ::v-deep(strong) {
    font-weight: 600;
  }
}

.information::v-deep {
  ul,
  ol {
    list-style: none;

    ul {
      padding: 0;
    }
  }

  ol {
    list-style: decimal outside none;
    margin-left: 50px;
  }

  ul {
    list-style: disc outside none;
    margin-left: 50px;
  }

  li {
    padding: 0.5rem 0;
  }

  a {
    text-decoration: underline;
  }

  p {
    padding: 0.25em 0;
  }
}
</style>
