import formHttp from "@/http/formHttp";

interface UseSubscriberReturn {
  newReference: () => Promise<string>;
  hasReference: () => Promise<boolean>;
  removeReference: () => void;
  refreshCurrentStep: () => void;
  getCurrentStep: () => number;
  setBroker: (broker: string) => void;
  getBroker: () => string | null;
  clearLocalStorage: () => void;
}

export function useSubscriber(): UseSubscriberReturn {
  const newReference = async (): Promise<string> => {
    const { data } = await formHttp.postReference();
    localStorage.setItem("reference", data.reference);
    return data.reference;
  };

  const hasReference = async (): Promise<boolean> => {
    try {
      const { data } = await formHttp.getForm();
      const localReference = localStorage.getItem("reference") !== null;
      return data && localReference;
    } catch (response) {
      removeReference();
      return false;
    }
  };

  const removeReference = (): void => {
    return localStorage.removeItem("reference");
  };

  const refreshCurrentStep = async (): Promise<void> => {
    const { data } = await formHttp.getForm();
    localStorage.setItem("step", data.content.current_step);
  };

  const getCurrentStep = (): number => {
    return Number(localStorage.getItem("step"));
  };

  const setBroker = (broker: string): void => {
    return localStorage.setItem("broker", broker);
  };

  const getBroker = (): string | null => {
    return localStorage.getItem("broker");
  };

  const clearLocalStorage = (): void => {
    localStorage.removeItem("reference");
    localStorage.removeItem("step");
    localStorage.removeItem("broker");
  };

  return {
    newReference,
    hasReference,
    removeReference,
    refreshCurrentStep,
    getCurrentStep,
    setBroker,
    getBroker,
    clearLocalStorage,
  };
}
